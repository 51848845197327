.loader {
    border: 8px solid #f3f3f3;
    border-radius: 70%;
    border-top: 8px solid #3498db;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .sunset-banner {
    background-color: rgb(220 38 38);
    display: flex;
    padding: 10px 60px;
    position: sticky;
    top: 0;
    z-index: 99;
  }

  .sunset-banner-heading {
    color: white;
    font-size: 20px;
    margin-bottom: 10px;
    margin-right: 10px;
  }

  .sunset-banner-desc {
    color: white;
    font-size: 16px;
    margin-bottom: 0px;
    margin-right: 10px;
  }
  
  .sunset-banner-cta {
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    padding: 10px 20px;
    border-radius: 20px;
    display: inline-block; 
    border: 1px solid #ffffff; 
    margin-left: auto;
    font-weight: bold;
  }

  .sunset-banner-cta:hover {
    text-decoration: underline;
    color: white;
  }

@media only screen and (max-width: 767px) {
  .sunset-banner {
      padding: 10px 10px; 
      flex-direction: column;
      align-items: center; 
      justify-content: center; 
  }

  .sunset-banner-heading {
      font-size: 16px; 
  }

  .sunset-banner-desc {
      font-size: 14px; 
  }

  .sunset-banner-cta {
      padding: 8px 12px; 
      font-size: 14px; 
      margin-top: 10px;
      margin-left: 0;
  }
}